import React from 'react';
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

//Global Styles (applied to all views + components)
import './Styles/Global.scss';

// Components
import Main from './Views/Main';
import Confirmation from './Views/Confirmation';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Main />}></Route>
      <Route path="/confirmation" element={<Confirmation />}></Route>
    </Routes>
  </BrowserRouter>
);