import React from 'react';

import HeaderBar from '../Components/HeaderBar';
import Footer from '../Components/Footer';
import '../Styles/Confirmation.scss'

export default function Confirmation(props) {
    return (
        <div id="Confirmation">
            <HeaderBar />

            <div className="center-text">
                <h1>Thank you for registering!</h1>
                <h2>We'll be in contact with you as soon as possible.</h2>

                <a href="https://sionic.io/"><button>Sionic Website</button></a>
            </div>

            <Footer />
        </div>
    )
}