import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';

//Components + Views
import { default as DropdownSelector } from 'react-select'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { TextInput, ZipCode } from '../Components/Fields.tsx';
import HeaderBar from '../Components/HeaderBar';
import Footer from '../Components/Footer';

//Banks Lists Data
import { tierOneBanks, tierTwoBanks } from '../BankLists.js';

//Form Field Validator
import { Verify } from '../Helpers/Verify.ts';

//Styles
import '../Styles/Main.scss';

//Images
import { ReactComponent as PBBLogo } from '../img/pay-by-bank.svg';

export default function Main() {
  const inputSpacing = '1rem';
  const ALL_BANKS = tierOneBanks.concat(tierTwoBanks);
  const dev = false;
  const emptyForm = { 
    bank: '',
    name: '', 
    email: '', 
    phone: null, 
    business_name: '',
    business_addr_street: '',
    business_addr_city: '',
    business_addr_state: '',
    business_addr_zip: '',
  }
  const defaultPrefilled = { 
    bank: 'Bank of America',
    name: 'Adam Davis', 
    email: 'davis@sionic.io', 
    phone: '8106602315', 
    business_name: 'Happy Timez',
    business_addr_street: '8102 Waterslide Street',
    business_addr_city: 'Atlanta',
    business_addr_state: 'GA',
    business_addr_zip: '30338',
  }

  const POS_OPTIONS = [
    {value: 'Clover', label: 'Clover'},
    {value: 'Toast', label: 'Toast'},
    {value: 'Revel', label: 'Revel'},
    {value: 'Square', label: 'Square'},
    {value: 'Micros', label: 'Micros'},
    {value: 'Lightspeed', label: 'Lightspeed'},
    {value: 'Other', label: 'Other'},
  ];

  const PROCESSOR_OPTIONS = [
    {value: 'Global Payments', label: 'Global Payments'},
    {value: 'Fiserv', label: 'Fiserv'},
    {value: 'Worldpay', label: 'Worldpay'},
    {value: 'Chase', label: 'Chase'},
    {value: 'Elavon', label: 'Elavon'},
    {value: 'Other', label: 'Other'},
  ];

  //Form State
  const [ formData, setFormData ]         = useState(dev ? defaultPrefilled : emptyForm);
  const [ title, setTitle ]               = useState({title: ''});
  const [ pos, setPos ]                   = useState([]);
  const [ payProcessor, setPayProcessor ] = useState([]);

  let navigate = useNavigate();


  async function postData(url='', data={}) {
    try {
      const response = await fetch(url, { 
      method: 'POST', 
      headers: { 'Content-Type': 'application/json', },
      mode: 'cors',
      cache: 'no-cache',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data) 
    });
      return response;
    } catch(e) { console.error(e); return null; }
  }

  function onVerify() {
    const posObject       = { pos: pos };
    const processorObject = { payment_processor: payProcessor };

    /** Verifies form data then calls func for POST-ing */
    if(!(Object.values(formData).every(item => item))) {
      alert("Please fill out all required form fields")
      return;
    }

    if(!posObject.pos.length || !processorObject.payment_processor.length) {
      alert("Please fill all required form fields")
    }

    if(!(Verify.email(formData.email))) {
      alert("Please enter a valid email address.");
      return;
    }
    
    let data = { ...formData, ...title, ...posObject, ...processorObject }

    //Prod: https://us-east1-ulink-prod.cloudfunctions.net/form_submit
    //Dev: https://us-east1-ulink-242918.cloudfunctions.net/form_submit
    postData('https://us-east1-ulink-prod.cloudfunctions.net/form_submit', data)
      .then(() => {
        navigate('/confirmation')
      });
  };

  return (
    <div id="Main">
      <HeaderBar />

      <header> 
        <h1>Please complete the information below and a member of our team will contact you soon. </h1> 
        <PBBLogo />
      </header>

      <form id="Form">

        {/* Dropdown Bank Selector */}
        <div className="onboarding-dropdown-selector">
          <label htmlFor="BankSelector">Select your bank</label>
          <DropdownSelector 
            id="BankSelector" 
            options={ALL_BANKS} 
            placeholder='Select your bank...'
            onChange={opt => setFormData((data) => Object.assign({}, data, { bank: opt.value }))}
          />
        </div>

        {/* Dropdown POS Selector */}
        <div className="onboarding-dropdown-selector">
          <label htmlFor="PosSelector">Your POS systems</label>
          <DropdownSelector
            id="PosSelector"
            options={POS_OPTIONS}
            isMulti
            placeholder='Select all that apply...'
            onChange={opt => {
              const newArray = [];
              opt.forEach(object => {
                newArray.push(object.value);
              });

              setPos(newArray);
            }}
          />
        </div>

        {/* Dropdown Payment Processor Selector */}
        <div className="onbaording-dropdown-selector">
          <label htmlFor="ProcessorSelector">Your payment processors</label>
          <DropdownSelector
            id="ProcessorSelector"
            options={PROCESSOR_OPTIONS}
            isMulti
            placeholder='Select all that apply...'
            onChange={opt => {
              const newArray = [];
              opt.forEach(object => {
                newArray.push(object.value);
              });
              
              setPayProcessor(newArray);
            }}
          />
        </div>


        <div id="ContactInformation">
          <h3>Contact Information</h3>

          <TextInput 
            label='Name'
            name='name'
            placeholder='Your name'
            formData={formData} setFormData={setFormData}
          />

          <TextInput 
            type='email'
            label='Email'
            name='email'
            placeholder='johnsmith@gmail.com'
            topMargin={inputSpacing}
            formData={formData} setFormData={setFormData}
          />

          <div id="PhoneInput">
            <label htmlFor='MobilePhoneInput'>Mobile Phone</label>
            <PhoneInput 
                id="MobilePhoneInput"
                inputStyle={{width: '100%', minHeight: '2.37rem'}}
                inputClass={'phone-input'}
                disableDropdown={true}
                disableCountryCode={true}
                placeholder={'(123) 456 7890'}
                country={'us'}
                onlyCountries={['us']}
                value={formData.phone}
                onChange={(e) => setFormData((data) => Object.assign({}, data, {phone: e}))}
                isValid={(value, country) => {
                  if (value.match(/12345/)) return 'Invalid value: '+value+', '+country.name;
                  else if (value.match(/1234/)) return false;
                  else return true;
                }}
            />
          </div>

          <TextInput 
            label='Title'
            name='title'
            placeholder='ex: Business owner'
            optional={true}
            topMargin={inputSpacing}
            formData={title} setFormData={setTitle}
          />
        </div>

        <div id="BusinessInformation">
          <h3>Business Information</h3>

          <TextInput 
            label='Business name'
            name='business_name'
            placeholder="Your business name"
            topMargin={inputSpacing}
            formData={formData} setFormData={setFormData}
          />

          <div id="Address">
            <TextInput 
              label='Street address'
              name='business_addr_street'
              placeholder="ex: 1234 First Street"
              topMargin={inputSpacing}
              formData={formData} setFormData={setFormData}
            />

            <TextInput 
              label='City'
              name='business_addr_city'
              placeholder="City"
              topMargin={inputSpacing}
              formData={formData} setFormData={setFormData}
            />

            <div className="state-zip">
              <TextInput 
                width={'50%'}
                label='State'
                name='business_addr_state'
                placeholder="CA"
                maxChars={2}
                topMargin={inputSpacing}
                formData={formData} setFormData={setFormData}
              />

              <ZipCode 
                width={'100%'}
                label='Zip code'
                name='business_addr_zip'
                placeholder="Zip code"
                maxChars={5}
                topMargin={inputSpacing}
                formData={formData} setFormData={setFormData}
              />
            </div>
          </div>
        </div>

        <div className="submit-button-wrapper">
          <button onClick={(e) => {
            e.preventDefault();
            onVerify();
          }}>Register</button>
        </div>
      </form>

      <Footer />
    </div>
  );
}
