import React from "react";
//Styles
import './Styles/Fields.scss';


export function TextInput(props: {
    /** declares the type of input - defaults to 'text' */
    type?: string,
    /** name attribute of input and property name in formData */
    name: string,
    /** Label text which will describe this input */
    label: string,
    placeholder?: string,
    /** Bool for if this input is read-only */
    readOnly?: boolean,
    /** Bool for if this input is optional (defaults to false) */
    optional?: boolean,
    /** Maximum amount of characters allowed in the field */
    maxChars?: number,
    /** Object containing all form fields */
    formData: {},
    setFormData: ((x: (data: any) => any) => void),
    /** Denotes amount of top margin seperation */
    topMargin?: string,
    /** Denotes the width of the input (100% if left undefined) */
    width?: string,
    /** Override default styles class */
    customClass?: string,
}) {
    const { name, customClass, optional, maxChars } = props;

    return (
        <div style={{ marginTop: props.topMargin ?? '0' }}>
            <label className="label-standard"> { props.label } <span>{optional ? '- Optional' : ''}</span> </label>
            <input 
                style={{width: props.width ?? '100%'}}
                type={ props.type ?? 'text' }
                name={name} 
                placeholder={props.placeholder ?? ''} 
                readOnly={props.readOnly ?? false}
                value={props.formData[name]}
                className={`text-input-component ${customClass ?? ''}`} 
                onChange={(e) => {
                    if(maxChars && e.target.value.length > maxChars) return;
                    props.setFormData((data) => Object.assign({}, data, { [name]: e.target.value }))
                }}
            />
            <span>{}</span>
        </div>
    )
}

export function ZipCode(props: {
    name: string,
    placeholder?: string,
    readOnly?: boolean,
    /** Denotes amount of top margin seperation */
    topMargin: string,
    customClass?: string,
    /** Denotes the width of the input (100% if left undefined) */
    width?: string,
    /** Object containing all form fields */
    formData: {},
    /** Maximum amount of characters allowed in the field */
    maxChars?: number,
    setFormData: ((x: (data: any) => any) => void),
}) {
    const { name, customClass, maxChars } = props;
    return (
        <div id="ZipCode" style={{ marginTop: props.topMargin ?? '0' }}>
                <label htmlFor="ZipCodeInput">Zip code</label>
                <input
                    id="ZipCodeInput"
                    className={`zipcode-input-component ${customClass ?? ''}`}
                    style={{width: props.width ?? '100%'}}
                    type="number"
                    value={props.formData[name]}
                    placeholder={props.placeholder ?? ''}
                    readOnly={props.readOnly ?? false}
                    onChange={(e) => { 
                        if(maxChars && e.target.value.length > maxChars) return;
                        props.setFormData((data) => Object.assign({}, data, { [name]: e.target.value }))
                    }}
                 />
        </div>
    )
}