import React from 'react';

// Styles
import './Styles/HeaderBar.scss';
//Images
import { ReactComponent as Logo } from '../img/sionic-logo.svg';

export default function HeaderBar(props) {
    return (
        <div id="HeaderBar">
            <Logo />
        </div>
    )
}