export const tierOneBanks = [
    { value: 'Bank of America', label: 'Bank of America' },
    { value: 'Bank of New York Mellon', label: 'Bank of New York Mellon' },
    { value: 'Capital One National Association', label: 'Capital One National Association' },
    { value: 'Citibank NA', label: 'Citibank NA' },
    { value: 'JPMorgan Chase', label: 'JPMorgan Chase' },
    { value: 'PNC Bank NA', label: 'PNC Bank NA' },
    { value: 'Truist Bank', label: 'Truist Bank' },
    { value: 'US Bank NA', label: 'US Bank NA' }
]



export const tierTwoBanks = [
    { value: '4Front Credit Union', label: '4Front Credit Union' },
    { value: 'AlaTrust Credit Union', label: 'AlaTrust Credit Union' },
    {
      value: 'American Bank & Trust Company Inc',
      label: 'American Bank & Trust Company Inc'
    },
    { value: 'American National Bank', label: 'American National Bank' },
    {
      value: 'American National Bank & Trust Co',
      label: 'American National Bank & Trust Co'
    },
    { value: 'Anderson Brothers Bank', label: 'Anderson Brothers Bank' },
    { value: 'Arthur State Bank', label: 'Arthur State Bank' },
    {
      value: 'Associated Credit Union of Texas',
      label: 'Associated Credit Union of Texas'
    },
    { value: 'Avidia Bank', label: 'Avidia Bank' },
    { value: 'Bank Independent', label: 'Bank Independent' },
    { value: 'Bank of Clarkson', label: 'Bank of Clarkson' },
    { value: 'Bank of Jackson Hole', label: 'Bank of Jackson Hole' },
    { value: 'Bank of Lexington Inc', label: 'Bank of Lexington Inc' },
    { value: 'Bank of San Francisco', label: 'Bank of San Francisco' },
    { value: 'Bank of the West', label: 'Bank of the West' },
    { value: 'Bank Star', label: 'Bank Star' },
    { value: 'BankChampaign NA', label: 'BankChampaign NA' },
    { value: 'BankNewport', label: 'BankNewport' },
    { value: 'BankProv', label: 'BankProv' },
    { value: 'Banner Bank', label: 'Banner Bank' },
    {
      value: 'Bar Harbor Bank and Trust',
      label: 'Bar Harbor Bank and Trust'
    },
    {
      value: '"Barrington Bank & Trust Company, NA"',
      label: '"Barrington Bank & Trust Company, NA"'
    },
    { value: 'BayCoast Bank', label: 'BayCoast Bank' },
    {
      value: 'Bayer Heritage Federal Credit Union',
      label: 'Bayer Heritage Federal Credit Union'
    },
    { value: 'BCB Community Bank', label: 'BCB Community Bank' },
    {
      value: 'Bethpage Federal Credit Union',
      label: 'Bethpage Federal Credit Union'
    },
    {
      value: '"Beverly Bank & Trust Company, NA"',
      label: '"Beverly Bank & Trust Company, NA"'
    },
    { value: 'Black Hills FCU', label: 'Black Hills FCU' },
    { value: 'Blue Ridge Bank NA', label: 'Blue Ridge Bank NA' },
    { value: 'BMO Harris Bank', label: 'BMO Harris Bank' },
    { value: 'Bravera Bank', label: 'Bravera Bank' },
    { value: 'Brenham National Bank', label: 'Brenham National Bank' },
    { value: 'Bridge Community Bank', label: 'Bridge Community Bank' },
    { value: 'Bryant Bank', label: 'Bryant Bank' },
    { value: 'Busey Bank', label: 'Busey Bank' },
    {
      value: 'Campus USA Credit Union',
      label: 'Campus USA Credit Union'
    },
    {
      value: 'Cape Cod Five Cents Savings Bank',
      label: 'Cape Cod Five Cents Savings Bank'
    },
    {
      value: 'Carthage Federal Savings & Loan',
      label: 'Carthage Federal Savings & Loan'
    },
    {
      value: 'Carvers Federal Savings Bank',
      label: 'Carvers Federal Savings Bank'
    },
    { value: 'Cashmere Valley Bank', label: 'Cashmere Valley Bank' },
    { value: 'CBI Bank & Trust', label: 'CBI Bank & Trust' },
    { value: 'CBW Bank', label: 'CBW Bank' },
    { value: 'Centreville Bank', label: 'Centreville Bank' },
    { value: 'Century Bank', label: 'Century Bank' },
    { value: 'Chain Bridge Bank NA', label: 'Chain Bridge Bank NA' },
    {
      value: 'Charlotte State Bank & Trust',
      label: 'Charlotte State Bank & Trust'
    },
    { value: 'Chesapeake Bank', label: 'Chesapeake Bank' },
    {
      value: 'Chevron Federal Credit Union',
      label: 'Chevron Federal Credit Union'
    },
    { value: 'ChoiceOne Bank', label: 'ChoiceOne Bank' },
    { value: 'CIBM Bank', label: 'CIBM Bank' },
    {
      value: 'Citadel Federal Credit Union',
      label: 'Citadel Federal Credit Union'
    },
    { value: 'Citizens Bank NA', label: 'Citizens Bank NA' },
    {
      value: 'Citizens Bank of Americus',
      label: 'Citizens Bank of Americus'
    },
    {
      value: 'Citizens Bank of Wisconsin',
      label: 'Citizens Bank of Wisconsin'
    },
    { value: 'Citizens National Bank', label: 'Citizens National Bank' },
    { value: 'Citizens State Bank', label: 'Citizens State Bank' },
    {
      value: 'Citizens State Bank of New Castle',
      label: 'Citizens State Bank of New Castle'
    },
    { value: 'City Bank', label: 'City Bank' },
    {
      value: 'Clackamas Federal Credit Union',
      label: 'Clackamas Federal Credit Union'
    },
    { value: 'CNB Bank', label: 'CNB Bank' },
    { value: 'Coastal Heritage Bank', label: 'Coastal Heritage Bank' },
    { value: 'Comerica Bank', label: 'Comerica Bank' },
    {
      value: 'Commercial Bank of California',
      label: 'Commercial Bank of California'
    },
    {
      value: 'Community Bank of Mississippi',
      label: 'Community Bank of Mississippi'
    },
    {
      value: 'Community Bank of the Bay',
      label: 'Community Bank of the Bay'
    },
    {
      value: 'Community Financial Credit Union',
      label: 'Community Financial Credit Union'
    },
    { value: 'Community First Bank', label: 'Community First Bank' },
    { value: 'ConnectOne Bank', label: 'ConnectOne Bank' },
    {
      value: 'Corporate America Credit Union',
      label: 'Corporate America Credit Union'
    },
    {
      value: 'Corporate One Federal Credit Union',
      label: 'Corporate One Federal Credit Union'
    },
    { value: 'Crane Credit Union', label: 'Crane Credit Union' },
    {
      value: 'Credit Union of Georgia',
      label: 'Credit Union of Georgia'
    },
    { value: 'Credit Union of Texas', label: 'Credit Union of Texas' },
    { value: 'Crews Bank & Trust', label: 'Crews Bank & Trust' },
    { value: 'Cross River Bank', label: 'Cross River Bank' },
    {
      value: '"Crystal Lake Bank & Trust Company, NA"',
      label: '"Crystal Lake Bank & Trust Company, NA"'
    },
    { value: 'Dallas Capital Bank NA', label: 'Dallas Capital Bank NA' },
    {
      value: 'Desco Federal Credit Union',
      label: 'Desco Federal Credit Union'
    },
    { value: 'Dogwood State Bank', label: 'Dogwood State Bank' },
    {
      value: 'Dow Chemical Employees Credit Union',
      label: 'Dow Chemical Employees Credit Union'
    },
    {
      value: 'Eagle Louisiana Federal Credit Union',
      label: 'Eagle Louisiana Federal Credit Union'
    },
    { value: 'Eastern Michigan Bank', label: 'Eastern Michigan Bank' },
    { value: 'Edmonton State Bank', label: 'Edmonton State Bank' },
    { value: 'Embers Credit Union', label: 'Embers Credit Union' },
    { value: 'Endeavor Bank', label: 'Endeavor Bank' },
    { value: 'Englewood Bank & Trust', label: 'Englewood Bank & Trust' },
    {
      value: 'ESL Federal Credit Union',
      label: 'ESL Federal Credit Union'
    },
    { value: 'Essex Savings Bank', label: 'Essex Savings Bank' },
    { value: 'ETrade', label: 'ETrade' },
    {
      value: 'Farmers & Merchants Bank (Salisbury)',
      label: 'Farmers & Merchants Bank (Salisbury)'
    },
    {
      value: 'Farmers & Merchants Bank (Timberville)',
      label: 'Farmers & Merchants Bank (Timberville)'
    },
    { value: 'Field & Main Bank', label: 'Field & Main Bank' },
    { value: 'Fifth Third Bank', label: 'Fifth Third Bank' },
    {
      value: 'First American Bank & Trust Company',
      label: 'First American Bank & Trust Company'
    },
    { value: 'First Bank (Clewiston)', label: 'First Bank (Clewiston)' },
    {
      value: 'First Bank (Creve Couer)',
      label: 'First Bank (Creve Couer)'
    },
    { value: 'First Bank (Kansas)', label: 'First Bank (Kansas)' },
    {
      value: 'First Bank and Trust Company',
      label: 'First Bank and Trust Company'
    },
    { value: 'First Bank of Dalton', label: 'First Bank of Dalton' },
    {
      value: 'First Bank of Manhattan',
      label: 'First Bank of Manhattan'
    },
    {
      value: 'First Central State Bank',
      label: 'First Central State Bank'
    },
    {
      value: 'First Commonwealth Bank',
      label: 'First Commonwealth Bank'
    },
    {
      value: 'First Community Bank (TX)',
      label: 'First Community Bank (TX)'
    },
    {
      value: 'First Community Bank (VA)',
      label: 'First Community Bank (VA)'
    },
    { value: 'First Fidelity Bank', label: 'First Fidelity Bank' },
    { value: 'First Financial Bank', label: 'First Financial Bank' },
    {
      value: 'First Midwest of Dexter',
      label: 'First Midwest of Dexter'
    },
    {
      value: 'First Midwest of Poplar Bluff',
      label: 'First Midwest of Poplar Bluff'
    },
    { value: 'First National Bank', label: 'First National Bank' },
    {
      value: 'First National Bank in Philip',
      label: 'First National Bank in Philip'
    },
    {
      value: 'First National Bank of Coffee County',
      label: 'First National Bank of Coffee County'
    },
    { value: 'First State Bank', label: 'First State Bank' },
    {
      value: 'First State Bank & Trust Company Inc',
      label: 'First State Bank & Trust Company Inc'
    },
    {
      value: 'First State Bank of Middlebury',
      label: 'First State Bank of Middlebury'
    },
    { value: 'First Volunteer Bank', label: 'First Volunteer Bank' },
    { value: 'FirstBank', label: 'FirstBank' },
    { value: 'Flint Community Bank', label: 'Flint Community Bank' },
    { value: 'Florence Bank', label: 'Florence Bank' },
    { value: 'Founders Bank', label: 'Founders Bank' },
    {
      value: 'Franklin First Federal Credit Union',
      label: 'Franklin First Federal Credit Union'
    },
    {
      value: 'Gather Federal Credit Union',
      label: 'Gather Federal Credit Union'
    },
    {
      value: 'Germantown Trust & Savings Bank',
      label: 'Germantown Trust & Savings Bank'
    },
    { value: 'Glenwood State Bank', label: 'Glenwood State Bank' },
    {
      value: 'Goldenwest Federal Credit Union',
      label: 'Goldenwest Federal Credit Union'
    },
    { value: 'Gorham Savings Bank', label: 'Gorham Savings Bank' },
    { value: 'Habib American Bank', label: 'Habib American Bank' },
    { value: 'Hawthorn Bank', label: 'Hawthorn Bank' },
    { value: 'High Plains Bank', label: 'High Plains Bank' },
    {
      value: '"Hinsdale Bank & Trust Company, NA"',
      label: '"Hinsdale Bank & Trust Company, NA"'
    },
    { value: 'HSBC Bank USA NA', label: 'HSBC Bank USA NA' },
    {
      value: 'Hudson Valley Credit Union',
      label: 'Hudson Valley Credit Union'
    },
    {
      value: 'Huntington National Bank',
      label: 'Huntington National Bank'
    },
    { value: 'Incredible Bank', label: 'Incredible Bank' },
    { value: 'INTRUST Bank', label: 'INTRUST Bank' },
    { value: 'Ion Bank', label: 'Ion Bank' },
    { value: 'Isabella Bank', label: 'Isabella Bank' },
    {
      value: 'Jackson Area Federal Credit Union',
      label: 'Jackson Area Federal Credit Union'
    },
    { value: 'John Marshall Bank', label: 'John Marshall Bank' },
    { value: 'Jonesburg State Bank', label: 'Jonesburg State Bank' },
    {
      value: '"KEB Hana Bank USA, NA"',
      label: '"KEB Hana Bank USA, NA"'
    },
    {
      value: 'Keybank National Association',
      label: 'Keybank National Association'
    },
    {
      value: 'Kimberly Clark Credit Union',
      label: 'Kimberly Clark Credit Union'
    },
    { value: 'Kitsap Bank', label: 'Kitsap Bank' },
    {
      value: '"Lake Forest Bank & Trust Company, NA"',
      label: '"Lake Forest Bank & Trust Company, NA"'
    },
    { value: 'Lakeside Bank', label: 'Lakeside Bank' },
    { value: 'Liberty National Bank', label: 'Liberty National Bank' },
    {
      value: '"Libertyville Bank & Trust Company, NA"',
      label: '"Libertyville Bank & Trust Company, NA"'
    },
    {
      value: 'Logix Federal Credit Union',
      label: 'Logix Federal Credit Union'
    },
    {
      value: 'Los Angeles Federal Credit Union',
      label: 'Los Angeles Federal Credit Union'
    },
    { value: 'Lowry State Bank', label: 'Lowry State Bank' },
    { value: 'M&T Bank', label: 'M&T Bank' },
    { value: 'MA Bank', label: 'MA Bank' },
    { value: 'Maquoketa State Bank', label: 'Maquoketa State Bank' },
    {
      value: 'Marine Bank and Trust Company',
      label: 'Marine Bank and Trust Company'
    },
    { value: 'Meade County Bank', label: 'Meade County Bank' },
    { value: 'Meadows Bank', label: 'Meadows Bank' },
    {
      value: 'Members 1st Federal Credit Union',
      label: 'Members 1st Federal Credit Union'
    },
    {
      value: 'Meredith Village Savings Bank',
      label: 'Meredith Village Savings Bank'
    },
    {
      value: 'Merrimack County Savings Bank',
      label: 'Merrimack County Savings Bank'
    },
    {
      value: 'Michigan State University FCU',
      label: 'Michigan State University FCU'
    },
    { value: 'Middletown Valley Bank', label: 'Middletown Valley Bank' },
    { value: 'MidFirst Bank', label: 'MidFirst Bank' },
    {
      value: 'Monticello Banking Company',
      label: 'Monticello Banking Company'
    },
    {
      value: 'Morgan Stanley Private Bank NA',
      label: 'Morgan Stanley Private Bank NA'
    },
    { value: 'MUFG Union Bank NA', label: 'MUFG Union Bank NA' },
    { value: 'MVB Bank Inc', label: 'MVB Bank Inc' },
    { value: 'MyPoint Credit Union', label: 'MyPoint Credit Union' },
    { value: 'Nano Banc', label: 'Nano Banc' },
    { value: 'National Bank of Texas', label: 'National Bank of Texas' },
    {
      value: 'National Grand Bank of Marblehead',
      label: 'National Grand Bank of Marblehead'
    },
    {
      value: 'New Valley Bank & Trust',
      label: 'New Valley Bank & Trust'
    },
    { value: 'Newtown Savings Bank', label: 'Newtown Savings Bank' },
    {
      value: '"North Shore Bank, a Co-operative Bank"',
      label: '"North Shore Bank, a Co-operative Bank"'
    },
    { value: 'North State Bank', label: 'North State Bank' },
    {
      value: '"Northbrook Bank & Trust Company, NA"',
      label: '"Northbrook Bank & Trust Company, NA"'
    },
    { value: 'Northeast Bank (Maine)', label: 'Northeast Bank (Maine)' },
    {
      value: 'Northeast Bank (Minnesota)',
      label: 'Northeast Bank (Minnesota)'
    },
    { value: 'Numerica Credit Union', label: 'Numerica Credit Union' },
    {
      value: 'Oak Valley Community Bank',
      label: 'Oak Valley Community Bank'
    },
    { value: 'Ocean Bank', label: 'Ocean Bank' },
    { value: 'Ohnward Bank & Trust', label: 'Ohnward Bank & Trust' },
    { value: 'Old Missouri Bank', label: 'Old Missouri Bank' },
    {
      value: '"Old Plank Trail Community Bank, NA"',
      label: '"Old Plank Trail Community Bank, NA"'
    },
    {
      value: 'Omni Community Credit Union',
      label: 'Omni Community Credit Union'
    },
    { value: 'One Florida Bank', label: 'One Florida Bank' },
    { value: 'Origin Bank', label: 'Origin Bank' },
    { value: 'Pacific National Bank', label: 'Pacific National Bank' },
    {
      value: 'Pacific Service Credit Union',
      label: 'Pacific Service Credit Union'
    },
    { value: 'Paragon Bank', label: 'Paragon Bank' },
    { value: 'Peapack-Gladstone Bank', label: 'Peapack-Gladstone Bank' },
    { value: 'Pegasus Bank', label: 'Pegasus Bank' },
    { value: 'Peoples Bank', label: 'Peoples Bank' },
    {
      value: 'Peoples Bank of Alabama',
      label: 'Peoples Bank of Alabama'
    },
    { value: 'Peoples National Bank', label: 'Peoples National Bank' },
    { value: 'Peoples State Bank', label: 'Peoples State Bank' },
    { value: 'Pioneer Bank', label: 'Pioneer Bank' },
    { value: '"Profinium, Inc."', label: '"Profinium, Inc."' },
    {
      value: 'Reading Co-Operative Bank',
      label: 'Reading Co-Operative Bank'
    },
    { value: 'Regions Bank', label: 'Regions Bank' },
    { value: 'River City Bank Inc', label: 'River City Bank Inc' },
    { value: 'RockPoint Bank NA', label: 'RockPoint Bank NA' },
    { value: 'Royal Bank', label: 'Royal Bank' },
    {
      value: 'Savings Bank of Walpole',
      label: 'Savings Bank of Walpole'
    },
    {
      value: '"Schaumburg Bank & Trust Company, NA"',
      label: '"Schaumburg Bank & Trust Company, NA"'
    },
    { value: 'Security Federal Bank', label: 'Security Federal Bank' },
    {
      value: 'Shell Federal Credit Union',
      label: 'Shell Federal Credit Union'
    },
    { value: 'Signature Bank', label: 'Signature Bank' },
    {
      value: 'Southern Michigan Bank and Trust',
      label: 'Southern Michigan Bank and Trust'
    },
    {
      value: 'Southwest Missouri Bank',
      label: 'Southwest Missouri Bank'
    },
    {
      value: 'Southwest National Bank',
      label: 'Southwest National Bank'
    },
    {
      value: 'Space Age Federal Credit Union',
      label: 'Space Age Federal Credit Union'
    },
    {
      value: 'Space Coast Credit Union',
      label: 'Space Coast Credit Union'
    },
    {
      value: '"St. Charles Bank & Trust Company, NA"',
      label: '"St. Charles Bank & Trust Company, NA"'
    },
    {
      value: '"State Bank of the Lakes, NA"',
      label: '"State Bank of the Lakes, NA"'
    },
    {
      value: 'Suma Yonkers Federal Credit Union',
      label: 'Suma Yonkers Federal Credit Union'
    },
    { value: 'TD Bank NA', label: 'TD Bank NA' },
    {
      value: 'Texas Bank and Trust Company',
      label: 'Texas Bank and Trust Company'
    },
    { value: 'Texas Community Bank', label: 'Texas Community Bank' },
    { value: 'The Bank of Advance', label: 'The Bank of Advance' },
    {
      value: 'The Commercial National Bank of Brady',
      label: 'The Commercial National Bank of Brady'
    },
    { value: 'The Cornerstone Bank', label: 'The Cornerstone Bank' },
    { value: 'The Dart Bank', label: 'The Dart Bank' },
    { value: 'The Dime Bank', label: 'The Dime Bank' },
    {
      value: 'The Neffs National Bank',
      label: 'The Neffs National Bank'
    },
    { value: 'Third Coast Bank SSB', label: 'Third Coast Bank SSB' },
    { value: 'Thomaston Savings Bank', label: 'Thomaston Savings Bank' },
    {
      value: 'Tinker Federal Credit Union',
      label: 'Tinker Federal Credit Union'
    },
    { value: '"Town Bank, NA"', label: '"Town Bank, NA"' },
    {
      value: 'Truity Federal Credit Union',
      label: 'Truity Federal Credit Union'
    },
    { value: 'UNCLE Credit Union', label: 'UNCLE Credit Union' },
    { value: 'United Bank of Iowa', label: 'United Bank of Iowa' },
    {
      value: 'University Credit Union',
      label: 'University Credit Union'
    },
    {
      value: 'Utah First Federal Credit Union',
      label: 'Utah First Federal Credit Union'
    },
    { value: 'VA Desert Pacific FCU', label: 'VA Desert Pacific FCU' },
    {
      value: 'Ventura County Credit Union',
      label: 'Ventura County Credit Union'
    },
    { value: 'Veridian Credit Union', label: 'Veridian Credit Union' },
    { value: 'Veritex Community Bank', label: 'Veritex Community Bank' },
    { value: 'Village Bank', label: 'Village Bank' },
    { value: '"Village Bank & Trust, NA"', label: '"Village Bank & Trust, NA"' },
    { value: 'Visions Federal Credit Union', label: 'Visions Federal Credit Union' },
    { value: 'Wauchula State Bank', label: 'Wauchula State Bank' },
    { value: 'Wells Fargo', label: 'Wells Fargo' },
    { value: 'Wells River Savings Bank', label: 'Wells River Savings Bank' },
    { value: 'West Point Bank', label: 'West Point Bank' },
    { value: 'West Shore Bank', label: 'West Shore Bank' },
    { value: 'Westside State Bank', label: 'Westside State Bank' },
    { value: 'WestStar Bank', label: 'WestStar Bank' },
    { value: '"Wheaton Bank & Trust Company, NA"', label: '"Wheaton Bank & Trust Company, NA"' },
    { value: 'Winter Park National Bank', label: 'Winter Park National Bank' },
    { value: '"Wintrust Bank, NA"', label: '"Wintrust Bank, NA"' },
    { value: 'Zions Bancorporation', label: 'Zions Bancorporation' },
    { value: 'Other', label: 'Other' }
]