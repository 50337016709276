import React from "react"
import './Styles/Footer.scss';


export default function Footer() {
    return (
      <div id="Footer">
        <span>Copyright 2010-2022 | Sionic Mobile Corporation | All Rights Reserved. </span>
      </div>
    )
  }